import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import { ReactTyped } from "react-typed";
import CountUp from 'react-countup';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet'
import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AvatarCreator, AvatarCreatorConfig, AvatarExportedEvent } from '@readyplayerme/react-avatar-creator';
import { Avatar } from "@readyplayerme/visage";

// const config: AvatarCreatorConfig = {
//   clearCache: true,
//   bodyType: 'fullbody',
//   quickStart: true,
//   language: 'en',
// };

// const style = { width: '100%', height: '80vh', border: 'none' };

function App() {
  
  const scriptUrl = "https://script.google.com/macros/s/AKfycbwVAzpN5atQqzXX6k_z8ODrr7WMlS6wi8sfUMqGag/exec"
    const formRef = useRef(null)
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({})
    const [showLoader, setShowLoader] = useState(false);

    const handleInputChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
    }

    const handleSubmit = (e) => {
        setShowLoader(true);

        e.preventDefault()
        const hiddenInput = document.createElement('input');
        hiddenInput.type = 'hidden';
        hiddenInput.name = 'Date';
        hiddenInput.value = new Date();
        formRef.current.appendChild(hiddenInput);
        fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current),


        }).then(res => {
            setShowLoader(false);
            setShowModal(true);
            console.log("SUCCESSFULLY SUBMITTED")

        })
            .catch(err => console.log(err))
    }

    const reloadPage = () => {
        window.location.reload(false);
    }

  ReactGA.initialize("G-BNP3Q74ZV3");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/home", title: "Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer" });

    const location = useLocation();
    const lastHash = useRef('');

  useEffect(() => {
    document.title = 'Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer';

    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  // const [avatarUrl, setAvatarUrl] = useState('https://models.readyplayer.me/6622734f63f883547d7442a9.glb');
  // const handleOnAvatarExported = (event: AvatarExportedEvent) => {
  //   setAvatarUrl(event.data.url);
  // };
 
  return (
    <div className="App">
      <Helmet>
            <title>Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer" />
            <meta property="og:site_name" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer"/>
            <meta property="og:url" content="https://ankitkapopara.tech/"/>
            <meta property="og:image" content="https://ankitkapopara.tech/static/media/thumbnail-img.87daf04dfe903ccfdd1a.png"></meta>
            <meta property="og:description" content="I help you build brand for your business at an affordable price. Thousands of clients have procured exceptional results while working with our dedicated team. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
Delivering work within time and budget which meets client’s requirements is our moto."/>
            <meta property="og:type" content="website"/>
            <meta content="I help you build brand for your business at an affordable price. Thousands of clients have procured exceptional results while working with our dedicated team. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
Delivering work within time and budget which meets client’s requirements is our moto." />
            <meta property="og:keywords" content="Web Developer Canada, Freelancer Canada, UI / UX Designer Canada, Graphic Designer Canada"/>
            <meta name="keywords" content="Web Developer Canada, Freelancer Canada, UI / UX Designer Canada, Graphic Designer Canada"/>
            <meta content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer" />
            <meta name="title" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer"/>
            <link rel="canonical" href="https://ankitkapopara.tech/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="fevicon.png" type="image/png" sizes="16x16"/>
        </Helmet>
      <Header></Header>

      {/* <Main Banner section start> */}
      <section id="home">
        <div className="home">
            <div class="custom-shape-divider">
              <div className="nameSection container">
                <div className='row'>
                  <div className='col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6'>
                    <div className='rightSec'>
                      <div className="text1">
                            <p>Hello, My Name Is</p>
                      </div>
                      <div className="text2">
                        <p>Ankit Kapopara</p>
                      </div>
                      <div className="text3">
                        <p>And I'm a <ReactTyped className="typing" strings={["Freelancer","Web Developer", "UI / UX Designer", "Graphic Designer"]} typeSpeed={100} backSpeed={30} loop/></p>
                      </div>
                      <div className="py-2">
                        <Link to="#contact" className="btn hire-button">Hire Me</Link>
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-sm-12 col-md-6 col-xl-6 col-xxl-6'>
                    <div className='leftsec'>
                    {/* <AvatarCreator subdomain="https://ankit-kapopara.readyplayer.me" config={config} style={style} onAvatarExported={handleOnAvatarExported} /> */}
                    {/* <Avatar modelSrc={avatarUrl} config={config} style={style}/> */}
                      <img className='img-fluid' src={require('./images/banner-new.png')} alt='Ankit Kapopara Porfolio'/>
                      <img className='img-fluid d-none' src={require('./images/thumbnail-img.png')} alt='Ankit Kapopara Porfolio'/>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
        </div>
      </section>
      {/* <Main Banner section end> */}

      {/* About me section start */}
      <section className="section" id="aboutme">
        <div className="about-wrap container">
          <div className="fade-text">
              <h2>ABOUT ME</h2>
              <p>Know Me More <span className="header-bottom-line"></span></p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 col-xxl-8" data-aos="fade-right" data-aos-duration="1000">
              <h3 className="title">I'm <span>Ankit Kapopara</span> a Web Developer</h3>
              <p>
                I help you build brand for your business at an affordable price. Thousands of clients have procured exceptional results 
                while working with our dedicated team. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              </p>
              <p>Delivering work within time and budget which meets client’s requirements is our moto.
              </p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 col-xxl-4" data-aos="fade-left" data-aos-duration="1000">
              <div className="personal-info">
                <b>Name:</b> Ankit Kapopara
              </div>
              <div className="personal-info">
		          <b>Email:</b> <a href='mailto:ankapopara.career@gmail.com'>ankapopara.career@gmail.com</a>
              </div>
              <div className="personal-info">
                <b>From:</b> Scarborough, Toronto, Canada.
              </div>
              <div className="py-3 text-center">
              <Link className="btn cv-btn shadow" to="/resume#top" target="_blank"> Resume</Link>
              </div>

            </div>
          </div>
          <div className="row card-count py-5" id="counters">
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 pb-3">
              <div className="card text-center " data-aos="fade-down" data-aos-duration="1000">
                  <div className="card-icon">
                    <i className="fas fa-file-code"></i>
                  </div>
                  <div className="count-text">
                    <CountUp className="counter" start={0} end={5} duration={2} enableScrollSpy={true} scrollSpyOnce={true}></CountUp><span>+</span>
                  </div>
                  <div className="count-title">
                    Years Experiance
                  </div>
              </div>
            </div> 
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 pb-3">
              <div className="card text-center " data-aos="fade-down" data-aos-duration="1200">
                <div className="card-icon">
                  <i className="fas fa-rocket"></i>
                </div>
                <div className="count-text">
                <CountUp className="counter" start={0} end={20} duration={2} enableScrollSpy={true} scrollSpyOnce={true}></CountUp><span>+</span>
                </div>
                <div className="count-title">
                  Projects Done
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4 pb-3">
              <div className="card text-center " data-aos="fade-down" data-aos-duration="1400">
                <div className="card-icon ">
                  <div className="icon-setting d-flex justify-content-center">
                    <div className="star-1">
                      <i className="fas fa-star"></i>
                    </div>
                    <div className="star-2">
                      <i className="fas fa-star"></i>
                    </div>
                    <div className="star-3">
                      <i className="fas fa-star"></i>
                    </div>
                    <div className="user-icon">
                      <i className="fas fa-users"></i>
                    </div>
                  </div>
                </div>
                <div className="count-text">
                <CountUp className="counter" start={0} end={15} duration={2} enableScrollSpy={true} scrollSpyOnce={true}></CountUp><span>+</span>
                </div>
                <div className="count-title">
                  Happy Clients 
                </div>
              </div>
            </div>
          </div>
        </div>                  
      
      </section>
       {/* <About me sectione start> */}

      {/* <What I Do section start> */}
      <section className="section" id="whatido">
        <div className="whatido-wrap container">
          <div className="fade-text">
            <h2>SERVICES</h2>
            <p>What I Do?<span className="header-bottom-line"></span></p>
          </div>
          <div className="row pt-3">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6  pt-3 " data-aos="fade-up">
                  <div className="service-icon">
                    <i className="fas fa-globe"></i>
                  </div>
                  <h5 className="service-title">Web Development</h5>
                  <p className="service-discription">I build and maintain websites. I rigorously work in the backend to make a website look great, 
                    work fast and perform well for a seamless experience.</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-8 col-xxl-6 pt-3" data-aos="fade-up" data-aos-delay="100">
                <div className="service-icon">
                  <i className="fas fa-palette"></i>
                </div>
              <div className="service-details">
                <h5 className="service-title">Graphic Designer</h5>
                <p className="service-discription">I focus on the logic of displaying elements in interactive designs, 
                  to optimize the user experience. Our scope of graphic design in UX covers the creation of beautiful 
                  designs that users find highly pleasurable, meaningful, and usable.</p>
              </div>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 pt-3" data-aos="fade-up" data-aos-delay="200">
                <div className="service-icon">
                  <i className="fas fa-desktop"></i>
                </div>
                <h5 className="service-title">Web Design</h5>
                <p className="service-discription">Web design is the process of planning, conceptualizing, and arranging 
                  content online and designing a website goes beyond aesthetics to include the website’s overall 
                  functionality. Web design also includes web apps, mobile apps, and user interface design.</p>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-lg-6 pt-3" data-aos="fade-up" data-aos-delay="300">
                <div className="service-icon">
                  <i className="fas fa-mobile"></i>
                </div>
                <h5 className="service-title"> Mobile Responsive Design</h5>
                <p className="service-discription">We are building a website that is responsive to the layout and /or 
                  content responses or adapts based on the size of the screen they are presented on. 
                  A responsive website automatically changes to fit the device you're reading it on.</p>
            </div>
          </div>
        </div>
      </section>
      {/* <What I Do section End > */}

      {/* <Domains section start> */}
      <section className="section" id="domains">
        <div className='container'>
          <div className="fade-text">
              <h2>DOMAINS</h2>
              <p>Industries I Work For<span className="header-bottom-line"></span></p>
            </div>
            <div className='work-cards'>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/social-media.png')} alt="Social Networking Domain"/>
                  <h3>Social <br></br>Networking</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/digital-marketing.png')} alt="Digital Marketing Domain"/>
                  <h3>Digital <br></br>Networking</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/online-store.png')} alt="Ecommerce Development Domain"/>
                  <h3>Ecommerce <br></br>Development</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/video-serives.png')} alt="Video Servics Domain"/>
                  <h3>Video <br></br>Service</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/banking.png')} alt="Banking Services Domain"/>
                  <h3>Banking <br></br>Services</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/enterprise.png')} alt="Enterprise Services Domain"/>
                  <h3>Enterprise <br></br>Service</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/edication.png')} alt="Education Services Domain"/>
                  <h3>Education <br></br>Service</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/tours-travel.png')} alt="Tour and Travels Domain"/>
                  <h3>Tour and <br></br>Travels</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/health.png')} alt="Health Services Domain"/>
                  <h3>Health <br></br>Services</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/events.png')} alt="Events and Tickets Domain"/>
                  <h3>Events and<br></br>Tickets</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/restorents.png')} alt="Restaurant Services Domain"/>
                  <h3>Restaurant <br></br>Services</h3>
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/consultant.png')} alt="Business Consultant Domain"/>
                  <h3>Business <br></br>Consultant</h3>
              </div>
            </div>
        </div>
      </section>
      {/* <Domains section end> */}

      {/* <Technologies section start> */}
      <section className="section" id="techiknow">
        <div className='container'>
          <div className="fade-text">
            <h2>TECHNOLOGIES</h2>
            <p>I Know<span className="header-bottom-line"></span></p>
          </div>
          <div className='tech-cards'>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/html.png')} alt="HTML"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/css.png')} alt="CSS"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/scss.png')} alt="SCSS"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/jquery.png')} alt="Jquery"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/js.png')} alt="JavaScript"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/bootstrap.png')} alt="Bootstrap"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/git.png')} alt="GIT"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/wordpress.png')} alt="Wordpess"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/odoo.png')} alt="Odoo"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/angular.png')} alt="Angular"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/react.png')} alt="React"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/photoshop.png')} alt="Photoshop"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/AI.png')} alt="Illustator"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/xd.png')} alt="XD"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/id.png')} alt="ID"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/figma.png')} alt="Figma"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/net.png')} alt="Dot Net"/>
        
              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/sql.png')} alt="SQL Server"/>

              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/php.png')} alt="PHP"/>

              </div>
              <div className="card-items">
                  <img className='img-fluid' src={require('./images/seo.png')} alt="Google SEO"/>

              </div>
          </div>
        </div>
      </section>
      {/* <Technologies section end> */}

      {/* <Projects section start> */}
      <section className="section" id="projects">
        <div className='container'>
          <div className="fade-text">
            <h2>PORTFOLIO</h2>
            <p>Project I Worked On<span className="header-bottom-line"></span></p>
          </div>
          <div className=''>
            <ul class="nav nav-pills" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Wesbites</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">UI / UX Designs</button>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                  <div className='project-cards'>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://ankitkapopara.tech/' target='_blanc'>
                        <img src={require('./images/portfolio-v2.png')} className="img-fluid" alt="Ankit Kapoapra" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Portfolio V2</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.cameronimmigration.com/' target='_blanc'>
                        <img src={require('./images/cameron_immigration.png')} className="img-fluid" alt="Cameron Immigration" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Cameron Immigration & Legal Services</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.thersimmigration.com/' target='_blanc'>
                        <img src={require('./images/thersimmigration.png')} className="img-fluid card-img-top" alt="RS Immigration and Consulting Services" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">RS Immigration and Consulting Services</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://ankapopara.github.io/ankitkapopara-portfolio/' target='_blanc'>
                        <img src={require('./images/portfolio-v1.png')} className="img-fluid card-img-top" alt="Ankit Kapopara" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Portfolio V1</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://netracounseling.com/' target='_blanc'>
                        <img src={require('./images/netra.png')} className="img-fluid card-img-top" alt="Netra Counseling Services" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Netra Counseling Services</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://twowaycommunications.in/' target='_blanc'>
                        <img src={require('./images/twowaycommunications.png')} className="img-fluid card-img-top" alt="Two-way communication" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Two-way communication</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://altcheeni.com/' target='_blanc'>
                        <img src={require('./images/altcheeni.png')} className="img-fluid card-img-top" alt="altcheeni" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">altcheeni</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.glamorouseventplanners.com/' target='_blanc'>
                        <img src={require('./images/glamorouseventplanners.png')} className="img-fluid card-img-top" alt="Glamorous Event Planners" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Glamorous Event Planners</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://kairosfood.eu/' target='_blanc'>
                        <img src={require('./images/kairosfood.png')} className="img-fluid card-img-top" alt="KairosFood " />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">KairosFood </h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.thebckspace.com/' target='_blanc'>
                        <img src={require('./images/thebckspace.png')} className="img-fluid card-img-top" alt="thebckspace " />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">thebckspace </h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.indusguru.in/' target='_blanc'>
                        <img src={require('./images/indusguru-in.png')} className="img-fluid card-img-top" alt="IndusGuru " />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">IndusGuru </h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://pranabydimple.com/' target='_blanc'>
                        <img src={require('./images/pranabydimple.png')} className="img-fluid card-img-top" alt="Prana By Dimple " />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Prana By Dimple </h5>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
              <div className='project-cards'>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/oriaUqyHQ8fcmDxhxSdwKV/Ankit-K-Portfolio-V2?page-id=0%3A1&type=design&node-id=110-11&viewport=-705%2C804%2C0.55&t=fRSXZTZvfOydKx0j-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/portfolio-v2.png')} className="img-fluid" alt="Ankit Kapoapra" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Portfolio V2</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/UK0gczYnaJNXZFHq6QNAlS/Cameron-Immigration?page-id=0%3A1&type=design&node-id=1-2&viewport=97%2C135%2C0.08&t=Oxgv2ELXbxRQHw9Y-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/cameron_immigration.png')} className="img-fluid" alt="Cameron Immigration" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Cameron Immigration & Legal Services</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/iikcx9jY5VySImb0tYuLyM/RS-Immigartion?page-id=0%3A1&type=design&node-id=100-60&viewport=187%2C140%2C0.08&t=cchaZ5DjWLeyz7Uy-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/thersimmigration.png')} className="img-fluid card-img-top" alt="RS Immigration and Consulting Services" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">RS Immigration and Consulting Services</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/tEXXVvj4HRXKH6NQCBFReI/2-Way-Communication?page-id=0%3A1&type=design&node-id=1-3&viewport=423%2C125%2C0.08&t=S2thlqyfMrM1ZhLx-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/twowaycommunications.png')} className="img-fluid card-img-top" alt="Two-way communication" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Two-way communication</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/TaYH3NRJl5ga3Lewlouh1t/ytaschool?page-id=0%3A1&type=design&node-id=2-2&viewport=347%2C75%2C0.06&t=Hf4PYOTQfOYybdsZ-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/Ytaschool.png')} className="img-fluid card-img-top" alt="YTA School" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">YTA School</h5>
                      </div>
                    </div>
                    <div class="card img-thumbnail">
                      <div className='card-image'>
                        <a href='https://www.figma.com/proto/rKSmvnZEUCWXoQtK801UiI/Camera-Becho?page-id=0%3A1&type=design&node-id=16-71&viewport=536%2C140%2C0.19&t=NIHgfs9WRNmkVDMl-1&scaling=min-zoom&mode=design' target='_blanc'>
                        <img src={require('./images/camera-becho.png')} className="img-fluid card-img-top" alt="Camera Becho" />
                        </a>
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">Camera Becho</h5>
                      </div>
                    </div>
                   
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Projects section end> */}

      {/* <Contact Us section start> */}
      <section className="section" id="contact">
        <div className="contact-wrap container">
          <div className="fade-text">
            <h2>CONTACT</h2>
            <p>Get In Touch<span className="header-bottom-line"></span></p>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4 col-xxl-4 pt-3" data-aos="fade-right" data-aos-duration="1000">
              <div className="card bg-white border-0 p-3">
                <div className="contact-padding">
                  <div className="contact-icon">
                    <i className="fas fa-location-arrow"></i>
                  </div>
                  <h5 className="contact-details">Location:</h5>
                  <p className="contact-details">Scarborough, Ontario, Canada.</p>
                </div>
                <div className="contact-padding">
                  <div className="contact-icon">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <h5 className="contact-details">Email:</h5>
                  <a className="contact-link" href='mailto:ankapopara.career@gmail.com'><p>ankapopara.career@gmail.com</p></a>
                </div>
                <div className="contact-padding">
                  <div className="contact-icon">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <h5 className="contact-details">Call:</h5>
                  <a className="contact-link" href="tel:+1-(437) 599-6561"><p>+1-(437) 599-6561</p></a>
                </div>
                <div className="contact-padding">
                  <div className="pt-3">
                    <h5><b>Follow:</b></h5>
                  </div>
                  <div className="social-icons">
                    <div>
                      <a className="contact-icon" href="https://www.linkedin.com/in/ankit-kapopara-4715a186" target="_blank"><i className="fab fa-linkedin"></i></a>
                    </div>
                    <div>
                      <a className="contact-icon" href="https://gitlab.com/kapopara" target="_blank"><i className="fab fa-gitlab"></i></a>
                    </div>
                    <div>
                      <a className="contact-icon" href="https://github.com/ankapopara" target="_blank"><i className="fab fa-github"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8 col-xxl-8 pt-3" data-aos="fade-left" data-aos-duration="1000">
              <div className="card bg-white border-0 p-3 pb-2">
                  <form method='POST' ref={formRef} onSubmit={handleSubmit}>
                      <div className="pb-2">
                          <h4><b>Send Us A Requirements </b></h4>
                      </div>  
                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-lg-3 form-group py-1">
                          <input type="text" className="contact-form-control" id="name" name="name" placeholder="Enter Your Name"/>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-lg-3 form-group py-1">
                          <input type="email" className="contact-form-control" id="email" name="email" placeholder="Enter Your Email ID"/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-lg-12 form-group py-1">
                          <input type="text" className="contact-form-control" id="subject" name="subject" placeholder="Enter Subject"/>
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12 col-lg-12 form-group py-1">
                          <textarea type="text" className="text-form-control" rows="4" cols="50" id="message" name="message" placeholder="Tell us more about your needs..."></textarea>
                        </div>
                      </div>
                      <div className="text-center py-4">
                        <button className="btn send-button" type="submit">Send</button>
                      </div>
                      {/* <div style={{display:'none'}} className="thankyou_message">
                        <h3 className="text-success py-3">Thanks for contacting us! We will get back to you soon!</h3>
                      </div> */}
                  </form>
                  
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Contact Us section End> */}
      <div className={`modal fade  ${showModal ? 'show' : ''}`} tabIndex="-1" id="thank_you_modal" style={{ display: showModal ? 'block' : 'none' }}>
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">

                        <div class="modal-body">
                            <div className='tick-svg'>
                                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="80" height="80" rx="40" fill="#02AF09" />
                                    <path d="M52.5707 29.6652C53.7212 28.5117 55.5866 28.5117 56.7371 29.6652C57.8735 30.8046 57.8874 32.6433 56.7789 33.7998L41.0972 53.4537C41.0745 53.4821 41.0503 53.5092 41.0247 53.5349C39.8742 54.6884 38.0089 54.6884 36.8584 53.5349L26.4629 43.112C25.3123 41.9584 25.3123 40.0881 26.4629 38.9346C27.6134 37.781 29.4787 37.781 30.6292 38.9346L38.8528 47.1798L52.4926 29.7536C52.5168 29.7226 52.5429 29.6931 52.5707 29.6652Z" fill="white" />
                                </svg>
                            </div>
                            <p>Thanks for getting in touch! We've received your message and appreciate you reaching out.</p>
                            <p>We're on it! will review your message and get back to you soon. </p>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn" onClick={reloadPage}>Ok</button>
                        </div>
                    </div>
                </div>
            </div>

      <Footer></Footer>
      <div style={{ display: showLoader ? 'block' : 'none' }}>
                <div className="loader " id="showLoader" >
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="circle"></div>
                    <div className="loading-text"><b>Please Wait...</b></div>
                </div>
            </div>

    </div>
  );
}

export default App;
