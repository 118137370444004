import React from 'react'
import Header from '../components/Header';
import Footer from '../components/Footer';
import ReactGA from "react-ga4";
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';


function Resume() {

    ReactGA.initialize("G-BNP3Q74ZV3");
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/resume", title: "Portfolio Ankit Kapopara | Resume" });

  useEffect(() => {
    document.title = 'Portfolio Ankit Kapopara | Resume';
  }, []);
    
  return (
    <div>
        <Helmet>
            <title>Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer</title>
            <meta content="width=device-width, initial-scale=1.0" name="viewport" />
            <meta property="og:title" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer" />
            <meta property="og:site_name" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer"/>
            <meta property="og:url" content="https://ankitkapopara.tech/"/>
            <meta property="og:image" content="https://ankitkapopara.tech/static/media/thumbnail-img.87daf04dfe903ccfdd1a.png"></meta>
            <meta property="og:description" content="I help you build brand for your business at an affordable price. Thousands of clients have procured exceptional results while working with our dedicated team. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
Delivering work within time and budget which meets client’s requirements is our moto."/>
            <meta property="og:type" content="website"/>
            <meta content="I help you build brand for your business at an affordable price. Thousands of clients have procured exceptional results while working with our dedicated team. when an unknown printer took a galley of type and scrambled it to make a type specimen book.
Delivering work within time and budget which meets client’s requirements is our moto." />
            <meta property="og:keywords" content="Web Developer Canada, Freelancer Canada, UI / UX Designer Canada, Graphic Designer Canada"/>
            <meta name="keywords" content="Web Developer Canada, Freelancer Canada, UI / UX Designer Canada, Graphic Designer Canada"/>
            <meta content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer" name="author" />
            <meta name="title" content="Portfolio Ankit Kapopara | Web Developer, Freelancer, UI / UX Designer, Graphic Designer"/>
            <link rel="canonical" href="https://ankitkapopara.tech/" />
            <meta http-equiv='cache-control' content='no-cache'/>
            <meta http-equiv='expires' content='0'/>
            <meta http-equiv='pragma' content='no-cache'/>
            <link rel="icon" href="fevicon.png" type="image/png" sizes="16x16"/>

        </Helmet>
        <Header></Header>
        <section className="section" id="resume">
            <div>
            <div className="resume-wrap container">
                <div className="fade-text">
                <h2>SUMMARY</h2>
                <p>Resume<span className="header-bottom-line"></span></p>
                </div>
                <div className="edu-title pb-3">
                    <h4>My Education</h4>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>CERTIFICATION OF Figma UI / UX</h4>
                            </div>
                            <div className="year-pass">
                            <span>2024</span>
                            </div>
                            <div className="school-title">
                            <h6>Udemy</h6>
                            <h6>Online.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>CERTIFICATION OF Angular - The complete Guide</h4>
                            </div>
                            <div className="year-pass">
                            <span>2022</span>
                            </div>
                            <div className="school-title">
                            <h6>Udemy</h6>
                            <h6>Online.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>Certification Of sass from beginner to expert</h4>
                            </div>
                            <div className="year-pass">
                            <span>2021</span>
                            </div>
                            <div className="school-title">
                            <h6>Udemy</h6>
                            <h6>Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>Certification Of Client Side Programming</h4>
                            </div>
                            <div className="year-pass">
                            <span>2020</span>
                            </div>
                            <div className="school-title">
                            <h6>Suven Cansultants & Technology Pvt Ltd</h6>
                            <h6>Chembur, Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>Certification Of ANDROID APPLICATION DEVELOPER</h4>
                            </div>
                            <div className="year-pass">
                            <span>2015</span>
                            </div>
                            <div className="school-title">
                            <h6>Innobuzz Knowlege Solutions</h6>
                            <h6>Andheri, Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>BACHELOR OF COMPUTER APPLICATION WITH SECOND class</h4>
                            </div>
                            <div className="year-pass">
                            <span>2011 - 2015</span>
                            </div>
                            <div className="school-title">
                            <h6>Indira Gandhi National Open University</h6>
                            <h6>Kirandebi Saraf Institute, Malad, Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>HIGHER SECONDARY CERTIFICATE WITH SECOND class</h4>
                            </div>
                            <div className="year-pass">
                                <span>Year of Passing 2011</span>
                            </div>
                            <div className="school-title">
                                <h6>Bal Bharati College of Commerce</h6>
                                <h6>Kandivali, Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>SECONDARY SCHOOL CERTIFICATE WITH FIRST class</h4>
                            </div>
                            <div className="year-pass">
                                <span>Year of Passing 2009</span>
                            </div>
                            <div className="school-title">
                                <h6>Sri Poorna Prajna Education Centre</h6>
                                <h6>Dahisar, Mumbai.</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" pb-3 pt-5" data-aos="fade-down" data-aos-duration="1000">
                    <div className="edu-title py-3">
                    <h4>My Experience</h4>
                    </div>
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>Full Circle Web (Remote) </h4>
                            </div>
                            <div className="year-pass">
                                <span>May 2024 – To Date</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Web Developer. (Part-time) (Contract)</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Designed and implemented custom WordPress themes and plugin intergration to meet specific client needs, enhancing site functionality and user experience with Elementor website builder.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Developed websites are fully responsive and optimized for various devices, improving user engagement and accessibility.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Developed wordpress pages with customise using HTML, CSS, and javascript.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Integrated WooCommerce to create and manage online stores, customizing the shopping experience to align with client branding.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Conducted performance optimization tasks, including code optimization, caching strategies, and image optimization, resulting in a 30% increase in site speed.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Implemented SEO best practices in theme development and content structuring, leading to a significant increase in organic search traffic.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Managed MySQL databases, performing regular backups, maintenance, and optimizations to ensure data integrity and site performance.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Liaised with clients to gather requirements, provide progress updates, and deliver training sessions on website management and content updates.l media, websites, email campaigns, and online advertising to enhance brand visibility and engagement.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Developed and integrated custom REST API solutions to extend website functionality and enable seamless third-party service interactions.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Implemented security measures such as SSL certificates, regular updates, and malware scanning, reducing the incidence of security breaches.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Optimize websites for search engines (SEO) to improve visibility and organic traffic.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Led multiple web development projects simultaneously, ensuring timely delivery and adherence to budget constraints.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Worked closely with graphic designers to integrate visual elements seamlessly into WordPress sites, ensuring cohesive and attractive designs.</span></h6>
                                <h6 className="my-3">Toronto, Canada</h6>
                            </div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>Freelance Consultant </h4>
                            </div>
                            <div className="year-pass">
                                <span>February 2023 – To Date</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Freelance Consultant.</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Conduct thorough consultations with clients to understand their business goals, target audience, and technical requirements.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Perform detailed analysis of client needs, translating requirements into actionable development plans.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Design visually appealing and functional websites using HTML, CSS, JavaScript, and other Web technologies.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Present design concepts and drafts to clients, explaining rationale and receiving feedback for revisions and improvements.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Create wireframes, user flows, and interactive prototypes using industry-standard tools such as Adobe XD, Sketch, or Figma.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Design visually appealing and intuitive user interfaces (UI) for web and mobile applications, focusing on usability and accessibility.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Create eye-catching and cohesive visual identities, including logos, color schemes, typography, and brand guidelines and design design elements to maintain corporate graphic image in Adobe Illustrator, PhotoShop, InDesign, and web-related software Canva and Figma.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Develop digital assets for social media, websites, email campaigns, and online advertising to enhance brand visibility and engagement.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Develop responsive websites that work seamlessly across various devices and screen sizes.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Implement interactive features, animations, and user interfaces to enhance user engagement.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Optimize websites for search engines (SEO) to improve visibility and organic traffic.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Conduct performance analysis and implement optimizations to enhance website speed, load times, and overall performance.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Continuously improve skills and expertise through self-learning, training, and professional development opportunities.</span></h6>
                                <h6 className="my-3">Toronto, Canada</h6>
                            </div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>IPG Team Dynamic / Mccan World Group</h4>
                            </div>
                            <div className="year-pass">
                                <span>March 2022 – December 2022</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Frontend Developer / AEM Web Authoring.</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Worked as a frontend developer for clients to develop the pages and content, improving page load times by 25%.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Implemented 10+ registration and API integration forms resulting in a 15% increase in lead conversion rates.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Proficient in AngularJs, Figma, Adobe XD, Adobe InDesign.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Experience in creating 15+ Emailers and Brochures using UI templates for drafting Newsletter emails, registration email and more.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Testing and QA 15+ Emailers using Litmus and Email on Acid.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Hand-coded 10+ landing pages with API database filters search.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Designed 60+ graphic images in Adobe Photoshop and Adobe illustrator to improve the website.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Diagnosed and fixed 30% defects in HTML, CSS and JS.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Responsible for 80% of content and image updates on page creation using Adobe Experience Manager (AEM).</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Experience in AEM 6.5 development with AEM templates, theming, components and authoring.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Maintained, developed, and designed 30+ webpages for clients and updated tickets using Monday.com.</span></h6>
                                <h6 className="my-3">Chinchpokli, Mumbai, India</h6>
                            </div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>Della Leaders Club / Della Groups</h4>
                            </div>
                            <div className="year-pass">
                                <span>June 2021 – February 2022</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Front-End Web Developer.</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Redesigned UI of Della Resorts 20+ web pages leading to 150K unique visitors per month.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Architected 10 webpages for DCLW (Della Creative Lifestyle Weekend).</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Transformed 40+ Adobe XD UI/UX files into to Responsive HTML/CSS files with used Bootstrap CSS framework.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Customized 10+ Emailers as per the UI template and invoice template files.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Integrated page section scroll animation with CSS and Javascript.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Conducted testing, installation, configuration, and troubleshooting of 20+ web pages within one month of joining.</span></h6>
                                <h6 className="my-3">Dadar, Mumbai, India</h6>
                            </div>
                        </div>
                    </div>
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                                <h4>Mahavir Consultant LLP</h4>
                            </div>
                            <div className="year-pass">
                                <span>April 2019 – May 2021</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Frontend Web Developer.</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Started as trainee frontend website developer 3 months with HTML 5, CSS 3, JavaScript.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Created ERP frontend development XML base with Odoo 8 framework.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1"> Collaborated with Clients for project management and assigning tasks to 10 teams members for 6+ websites and ERP.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Led and mentored 3+ interns.</span></h6>
                                <h6 className="my-3">Kandivali, Mumbai, India</h6>
                            </div>
                        </div>
                    </div>
                    
                    <div className="py-3">
                        <div className="card p-3">
                            <div className="title">
                            <h4>Time 2 Shop</h4>
                            </div>
                            <div className="year-pass">
                            <span>August 2018 – April 2019</span>
                            </div>
                            <div className="expe-title">
                                <h6 className="mb-3">Designation - Digital marketing expert.</h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Performed E-commerce market research for the public interest.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Ran daily campaigns and posted on Instagram and Facebook.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Handled all the queries on social media.</span></h6>
                                <h6><i className="fas fa-angle-right"></i> <span className="pl-1">Created 5+ banner graphics in Photoshop.</span></h6>
                                <h6 className="my-3">Mira-road, Mumbai</h6>
                            </div>
                        
                        </div>
                    </div>
                  
                </div>
                <div className="edu-title pt-5 py-3">
                <h4>My Skills</h4>
                </div>
                <div className="row pt-4">
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6" data-aos="fade-up" data-aos-delay="300">
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>HTML</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '95%'}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">95%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>JavaScript / JQuery</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>Bootstrap 4 / 5</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '95%'}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">95%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>Wordpress</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>XML</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>Figma UI / UX</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>Adobe XD UI / UX</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>Adobe Experience Manager(AEM) </p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6" data-aos="fade-up" data-aos-delay="500">
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>CSS / SCSS / SASS</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '95%'}} aria-valuenow="95" aria-valuemin="0" aria-valuemax="100">95%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>Git</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '90%'}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100">90%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>Odoo 8.0 / Odoo 13.0(Theme Building)</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>PhotoShop CC</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '80%'}} aria-valuenow="80" aria-valuemin="0" aria-valuemax="100">80%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                        <div className="skills-title">
                            <p>Laravel</p>
                        </div>
                        <div className="progress">
                            <div className="progress-bar" role="progressbar" style={{width: '50%'}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">50%</div>
                        </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>Angular JS</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>React Js</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                            </div>
                        </div>
                        <div className="pb-3">
                            <div className="skills-title">
                                <p>Google SEO</p>
                            </div>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '70%'}} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100">70%</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-5 text-center">
                    <a className="btn cv-btn shadow" href="./files/AnkitK-Resume-pdf.pdf" download="AnkitK-Resume" target="_blank">Download Resume</a>
                </div>
            </div>
            </div>
            
        </section>
        <Footer></Footer>
    </div>

  )
}

export default Resume;