import React from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import ScrollTop from './ScrollTop';


function Footer() {
    
  return (
    <div>
        <footer>
        <div class="container p-3">
            <div class="row">
            <div class="col-sm-6 col-md-12 col-lg-6 col-lg-3 text-left py-3">
                <p class="m-0 p-0">Designed & Developed by <span style={{color:'#dc3545'}}><b>Ankit Kapopara</b> </span></p>
            </div>
            <div class="col-sm-6 col-md-12 col-lg-6 col-lg-3  py-3">
                <p class="m-0 p-0 copy-text float-sm-start float-start float-md-start float-lg-end float-xl-end float-xxl-end">Copyright © 2024 All Rights Reserved.</p>
            </div>
            </div>

        </div>
    </footer>
    {/* <ScrollTop></ScrollTop> */}
    </div>
    
    
  )
}

export default Footer;