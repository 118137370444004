import React from 'react'
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';


  const Header = () => {

    const [showNavbar, setShowNavbar] = useState(false);
  
    const handleShowNavbar = () => {
      setShowNavbar(!showNavbar);
    }

    const [activeSection, setActiveSection] = useState(null);
  const observer = useRef(null);

  // const handleLinkClick = (section) => {
  //   setActiveSection(section);
  //   scrollToSection(section);
  // };

  // const scrollToSection = (section) => {
  //   const element = document.getElementById(section);
  //   element.scrollIntoView({ behavior: 'smooth' });
  // };

  useEffect(() => {
    //create new instance and pass a callback function
        observer.current = new IntersectionObserver((entries) => {
          const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
    //Update state with the visible section ID
          if (visibleSection) {
            setActiveSection(visibleSection.id);
          }
        });
    
    //Get custom attribute data-section from all sections
        const sections = document.querySelectorAll('section');
    
        sections.forEach((section) => {
          observer.current.observe(section);
        });
        
    //Cleanup function to remove observer
        return () => {
          sections.forEach((section) => {
            observer.current.unobserve(section);
          });
        };
      }, []);
      const activeStyle = {
        color: '$primary-text',
        fontWeight: '600',
        position: 'relative',
        transition: '0.2s linear',
      };

  return (

    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <h2>Portfolio.</h2>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
            <i class="fas fa-bars"></i>
        </div>
        <div className={`nav-elements  ${showNavbar && 'active'}`}>
          <ul>
            <li>
              <HashLink smooth to="/home#top" className={activeSection === 'home' ? 'active' : ''} style={activeSection === 
       'home' ? activeStyle : {}}>Home</HashLink>
            </li>
            <li> 
              <Link  smooth to="/#aboutme" className={activeSection === 'aboutme' ? 'active' : ''} style={activeSection === 
       'aboutme' ? activeStyle : {}} >About Me</Link>
            </li>
            <li>
              <Link  smooth to="/#whatido" className={activeSection === 'whatido' ? 'active' : ''} style={activeSection === 
       'whatido' ? activeStyle : {}} >What I Do</Link>
            </li>
            <li>
              <HashLink smooth to="/resume#top" className={activeSection === 'resume' ? 'active' : ''} style={activeSection === 
       'resume' ? activeStyle : {}}>Resume</HashLink>
            </li>
            <li>
              <Link smooth to="/#domains" className={activeSection === 'domains' ? 'active' : ''} style={activeSection === 
       'domains' ? activeStyle : {}} >Domains</Link>
            </li>
            <li>
              <Link  smooth to="/#techiknow" className={activeSection === 'techiknow' ? 'active' : ''} style={activeSection === 
       'techiknow' ? activeStyle : {}} >Technologies</Link>
            </li>
            <li>
              <Link smooth to="/#projects" className={activeSection === 'projects' ? 'active' : ''} style={activeSection === 
       'projects' ? activeStyle : {}} >Projects</Link>
            </li>
            {/* <li>
              <Link to="/testimonials">Testimonials</Link>
            </li> */}
            <li>
              <Link smooth to="/#contact" className={activeSection === 'contact' ? 'active' : ''} style={activeSection === 
       'contact' ? activeStyle : {}} >Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
  )
}

export default Header;